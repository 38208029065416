import Config from '../Config';

// Set a default so we don't need to check if mixpanel on every execution
let Analytics = {
	track: () => {},
	identify: () => {},
	page: () => {},
	group: () => {},
};
const initAnalytics = () => {
	
	const pathName = window.location.pathName;
	const hostName = window.location.host;

	window.appInsights?.trackPageView({name: hostName, path:pathName });
	window.appInsights?.trackEvent({name: `BMT` });

};
initAnalytics();

export const openFilters = ( store ) => {
	const UI = { ...store.state.UI };
	UI.showFilters = true;
	store.setState({ UI: UI });
};

export const openDetails = ( store ) => {
	const UI = { ...store.state.UI };
	UI.showDetails = true;
	store.setState({ UI: UI });
};

export const openStaffCard = ( store ) => {
	const UI = { ...store.state.UI };
	UI.showStaffCard = true;
	store.setState({ UI: UI });
};

export const openModal = ( store, { content = false, size = 'massive', closeIcon = false, closeOnOutsideClick = true} ) => {
	const UI = { ...store.state.UI };
	UI.modal.isOpen = true;
	if ( content ) {
		UI.modal.content = content;
	}
	if ( size ) {
		UI.modal.size = size;
    }
    if ( closeIcon ) {
        UI.modal.closeIcon = closeIcon;
    }

	UI.modal.closeOnOutsideClick = closeOnOutsideClick;

	store.setState({ UI: UI });
};

export const openTest = ( store ) => {
	const UI = { ...store.state.UI };
	UI.showTest = true;
	store.setState({ UI: UI });
};

export const toggleFilters = ( store ) => {
	const UI = { ...store.state.UI };
	UI.showFilters = !UI.showFilters;
	store.setState({ UI: UI });
};

export const toggleDetails = ( store ) => {
	const UI = { ...store.state.UI };
	UI.showDetails = !UI.showDetails;
	store.setState({ UI: UI });
};

export const toggleStaffCard = ( store ) => {
	const UI = { ...store.state.UI };
	UI.showStaffCard = !UI.showStaffCard;
	store.setState({ UI: UI });
};

export const toggleDisclaimerAccept = ( store ) => {
	const UI = { ...store.state.UI };
	UI.hasAccepted = !UI.hasAccepted;
	store.setState({ UI: UI });

	initAnalytics();
};

export const toggleModal = ( store ) => {
	const UI = { ...store.state.UI };
	UI.modal.isOpen = !UI.modal.isOpen;
	store.setState({ UI: UI });
};

export const toggleTest = ( store ) => {
	const UI = { ...store.state.UI };
	UI.showTest = !UI.showTest;
	store.setState({ UI: UI });
};

export const closeSidebars = ( store ) => {
	const UI = { ...store.state.UI };
	UI.showFilters = false;
	UI.showDetails = false;
	store.setState({ UI: UI });
};

export const closeStaffCard = ( store ) => {
	const UI = { ...store.state.UI };
	UI.showStaffCard = false;
	store.setState({ UI: UI });
};

export const closeModal = ( store ) => {
	const UI = { ...store.state.UI };
	UI.modal.isOpen = false;
	store.setState({ UI: UI });
};

export const closeAll = ( store ) => {
	const UI = { ...store.state.UI };
	UI.showFilters = false;
	UI.showDetails = false;
	UI.showStaffCard = false;
	UI.modal.isOpen = false;
	UI.showTest = false;
	store.setState({ UI: UI });
};

export const toggleFiltersIsOpen = ( store ) => {
	const UI = { ...store.state.UI };
	UI.filtersIsOpen = UI.showFilters;
	store.setState({ UI: UI });
};
export const filtersIsOpen = ( store ) => {
	const UI = { ...store.state.UI };
	UI.filtersIsOpen = true;
	store.setState({ UI: UI });
};
export const filtersIsClosed = ( store ) => {
	const UI = { ...store.state.UI };
	UI.filtersIsOpen = false;
	store.setState({ UI: UI });
};

export const toggleDetailsIsOpen = ( store ) => {
	const UI = { ...store.state.UI };
	UI.detailsIsOpen = UI.showDetails;
	store.setState({ UI: UI });
};
export const detailsIsOpen = ( store ) => {
	const UI = { ...store.state.UI };
	UI.detailsIsOpen = true;
	store.setState({ UI: UI });
};
export const detailsIsClosed = ( store ) => {
	const UI = { ...store.state.UI };
	UI.detailsIsOpen = false;
	store.setState({ UI: UI });
};

export const changeGroup = ( store, groupName ) => {
	const UI = { ...store.state.UI };
	UI.activeGroup = groupName;
	store.setState({ UI: UI });
};

export const changedDetailsTab = ( store, detailsTabIndex ) => {
	const UI = { ...store.state.UI };
	UI.detailsTabIndex = detailsTabIndex;
	store.setState({ UI: UI });
};

export const changeScreenOrientation = ( store, screenOrientation ) => {
	const UI = { ...store.state.UI };
	UI.screenOrientation = screenOrientation;
	store.setState({ UI: UI });
};
