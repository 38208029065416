// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
import preval from 'preval.macro';

export const environment = {
  environmentName: 'Local',
  production: false,
  authFailRedirectUrl: `https://incyte-qs1test.bellcyn.com/Content/Default/redirector.html?app=${process.env.NX_REDIRECT_PARAMETER || 'DEV_mosaic'}`,
  Qlik_config_host: 'incyte-qs1test.bellcyn.com',
  Qlik_FieldSales_appId: '0e79fe1b-fcc4-484f-87b7-2fcece85a948',
  Qlik_FieldSalesNation_appId: 'ac8d521b-ddbf-4244-b222-4e054b272f08',
  Qlik_Prof360_appId: 'fba173bb-269c-4847-806c-43de8c1a2a5e',
  listBuilder_class: 'ListBuilder_Test',
  profImagesServer: 'https://profimageswebapp.azurewebsites.net/ProfImages/',
  mdmUrlRedirect: 'https://incyte-mdm-portal-dev.bellcyn.com/authentication/login?returnUrl=https://incyte-mdm-portal-dev.bellcyn.com/hcp/details/',
  routerBasename: 'Mosaic',
  userEnvironmentSalt: 'Local',
  useWalkme: false,
  walkmeUrl: 'https://cdn.walkme.com/users/04999284a7e74f7eb8459e62bf2f34b9/test/walkme_04999284a7e74f7eb8459e62bf2f34b9_https.js',
  oceSamlUrl: 'https://incyte-us.okta.com/app/salesforce/exk4f7x8psMY6s10n297/sso/saml',
  oceSiteUrl: 'https://incyte-oce--fullcopy.lightning.force.com',
  HO1_ChatBotUrl: 'https://posit.incyte.com/content/7d97385d-d207-49c0-b6e8-fddf267723b2',
  HO2_ChatBotUrl: 'https://posit.incyte.com/content/7d97385d-d207-49c0-b6e8-fddf267723b2',
  HO3_ChatBotUrl: 'https://posit.incyte.com/content/be33dd79-89a9-418f-a34d-9597f8440922',
  showDisclaimer: true,
  hasPreCallPlanning: false,
  buildDate: preval`module.exports = Date.now()`,
  features: { 'HCO360': 'HCO360', 'chatbot': 'chatbot', 'NIKTIMVO': 'NIKTIMVO', 'NIKTIMVO_phase2': 'NIKTIMVO_phase2' }
};

export default environment;
