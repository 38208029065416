import { MosaicGlobal, QlikAPIFacade, QlikAppInstanceFacade } from '@trinity-incyte/api-interfaces';
import CryptoJS from 'crypto-js';
import Config from '../Config';
import { openDetails, openStaffCard, toggleFilters } from './UI';

declare var window: any;
declare var Mosaic: MosaicGlobal;

window.CryptoJS = CryptoJS;



export const requireNotFound = ( store ) => {
    const Qlik = { ...store.state.Qlik };
    Qlik.cannotAcquireRequire = true;
    store.setState({ Qlik });
};

const Authenticated = (signInId) => {
    var validatedId = signInId.replace(/[,;=| ]+/g, "_");
    window.appInsights.setAuthenticatedUserContext(validatedId);
}

export const registerQlik = ( store, qlik ) => {
    const Qlik = { ...store.state.Qlik };
    Qlik.qlik = qlik;
    Mosaic.QlikAPI = new QlikAPIFacade(qlik);
    store.setState({ Qlik });

    const glob = qlik.getGlobal( Config.Qlik.config );
    glob.getAuthenticatedUser(( reply ) => {
        const userHash = CryptoJS.SHA256( reply.qReturn ).toString();
        const userSplit = reply.qReturn.split('UserId=');
        if ( userSplit.length === 2 ) {
            window.WalkMeData = {
                UserID: userSplit[1],
            };
        }
	    window.appInsights.Authenticated(reply.qReturn);
    });
};

/**
 * START Selections
 * This is a quirky chunk of really custom selections logic
 * May need to get refactored at some point
 * You can use these vars in logic lower down
 */

// let isRoleSelected = false;
let isStaffSelected = false;

export const updateAppSelections = ( store, appId, selections ) => {
    const newState = store.state;
    newState.lastModified = Date.now();
    newState.Qlik.app[appId].selectionsLastModified = Date.now();

    // isRoleSelected = false;
    isStaffSelected = false;
    for ( let ii = 0; ii < selections.length; ii += 1 ) {
        const sel = selections[ii];
        if ( sel.fieldName === Config.Qlik.app1.ids.fields.Role ) {
            // isRoleSelected = true;
        } else if ( sel.fieldName === Config.Qlik.app1.ids.fields['Staff Name']) {
            isStaffSelected = true;
            const profId = sel.qSelected.substr(sel.qSelected.length - 10).replace(/(\(|\))/g, '');
            if (!isNaN(profId)) {
                newState.UI.mosaicHCPUrl = Config.App.mosaicHcpUrl + profId;
                newState.UI.hrefMosaicComm = Config.App.mdmUrlRedirect + profId;
                newState.UI.disableEdit = false;
            } else {
                newState.UI.mosaicHCPUrl = undefined;
                newState.UI.hrefMosaicComm = undefined;
                newState.UI.disableEdit = true;
            }
        } else if ( sel.fieldName === Config.Qlik.app1.ids.fields['Staff Prof ID']) {
            isStaffSelected = true;
            if (!isNaN(sel.qSelected)) {
                newState.UI.mosaicHCPUrl = Config.App.mosaicHcpUrl + sel.qSelected;
                newState.UI.hrefMosaicComm = Config.App.mdmUrlRedirect + sel.qSelected;
            } else {
                newState.UI.mosaicHCPUrl = undefined;
                newState.UI.hrefMosaicComm = undefined;
                newState.UI.disableEdit = true;
            }
        }
    }

    // Request update for certain QS Objects
    const manualUpdate = [
        Config.Qlik.app1.ids.masterItems['Transplant Center Text Box'],
        Config.Qlik.app1.ids.masterItems['Center Details Text Box'],
    ];
    manualUpdate.forEach(( val ) => {
        Mosaic.Qlik.app[appId].getObject( val );
    });

    store.setState( newState );
};

/**
 * END Selections
 */

const loadingApp = [];
const sessionObjects: any = [];
sessionObjects.getByName = ( name ) => {
    for ( let ii = 0; ii < sessionObjects.length; ii += 1 ) {
        if ( sessionObjects[ii].name && sessionObjects[ii].name === name ) {
            return sessionObjects[ii];
        }
    }
    return null;
};

const selectionsManager = {
    appId: null,
    start: Date.now(),
    latestSelect: Date.now(),
    latestComplete: Date.now(),
    objects: {},
    onSelectComplete: ( store ) => {
        selectionsManager.latestComplete = Date.now();
        // const { appId } = selectionsManager;
        // this handles a selection in a table in Qlik Sense to be confirmed?? Maybe??
        // window.Mosaic.Qlik.app[appId].forward();
        const newState = store.state;
        store.setState( newState );
    },
};

const registerListCallback = ( model, handle, store ) => {
    selectionsManager.objects[handle].parsingCallback = Date.now();
    selectionsManager.objects[handle].model = model;

    try {
        const selKeys = Object.keys( selectionsManager.objects );
        let haveAllCompleted = true; // Is this a safe default?
        // console.log( `running ${handle}` );
        for ( let ii = 0; ii < selKeys.length; ii += 1 ) {
            if ( selKeys[ii] !== handle && selectionsManager.objects[selKeys[ii]].parsingCallback < selectionsManager.latestComplete ) {
                // If a single *other* object is still waiting to return, we can break early
                // console.log( `running ${handle} : ${selKeys[ii]} has not completed` );
                haveAllCompleted = false;
                break;
            }
        }
        if ( haveAllCompleted ) {
            // console.log( `running ${handle} : executing onSelectComplete` );
            selectionsManager.onSelectComplete( store );
        }
        selectionsManager.objects[handle].latestCallback = Date.now();
    } catch ( e ) {
        console.warn( e );
    }
};

export const registerApp = ( store, appId ) => {
    const Qlik = { ...store.state.Qlik };

    if ( loadingApp.indexOf( appId ) === -1 ) { // App is not already loading
        loadingApp.push( appId );
        const app = Mosaic.QlikAPI.openApp( appId, Config.Qlik.config );
        Mosaic.Qlik.app[appId] = app;

        // Adds the Role to the WalkMe data
        app.createGenericObject({ role: { qStringExpression: `=[${Config.Qlik.app1.ids.fields['Emp Type']}]` }}, ( reply ) => {
            window.WalkMeData = window.WalkMeData || {};
            window.WalkMeData.Role = reply.role;
        });

        app.getList( 'BookmarkList', ( reply ) => {
            Qlik.app[appId] = {
                isLoaded: true,
                selections: [],
                bookmarks: reply.qBookmarkList,
            };
            loadingApp.splice( loadingApp.indexOf( appId ), 1 );
            store.setState({ Qlik });
        });

        const listeners = [
            {
                type: 'list',
                field: Config.Qlik.app1.ids.fields['ORG ID'],
                name: 'ORG',
                callback: ( reply, handle ) => {
                    const newState = store.state;
                    registerListCallback( reply, handle, store );

                    if ( reply.qListObject.qDimensionInfo.qStateCounts.qOption === 1 || reply.qListObject.qDimensionInfo.qStateCounts.qSelected === 1 ) {
                        newState.UI.isSelectedCenter = true;

                        if ( reply.qListObject.qDimensionInfo.qStateCounts.qOption === 1 || reply.qListObject.qDimensionInfo.qStateCounts.qSelected === 0 ) {
                            // Select the ORG Field and lock so that any non compatiable selections in Qlik do not clear the
                            Mosaic.Qlik.app[appId].field( reply.qListObject.qDimensionInfo.qFallbackTitle ).selectPossible()
                        .then(() => {
                                Mosaic.Qlik.app[appId].field( reply.qListObject.qDimensionInfo.qFallbackTitle ).lock();
                        });
                        }

                        if ( !newState.UI.showDetails ) {
                            openDetails( store );
                            newState.UI.showDetails = true;
                            toggleFilters( store );
                        }

                            Mosaic.Qlik.app[appId].variable.getContent( 'vGoogleMapLoc' )
                            .then(( replyVar1 ) => {
                                const vGLoc = replyVar1.qContent.qString;
                                newState.UI.hrefGoogleMap = vGLoc;
                            })
                            .then(() => Mosaic.Qlik.app[appId].variable.getContent( 'vCenterWebsite' ).then(( replyVar2 ) => {
                                    const vWebsite = replyVar2.qContent.qString;
                                    newState.UI.hrefWebIcon = vWebsite;
                                }))
                            .then(() => Mosaic.Qlik.app[appId].variable.getContent( 'vCRMLink' ).then(( replyVar3 ) => {
                                    const qvCRMLink = replyVar3.qContent.qString;
                                    newState.UI.hrefSalesforce = qvCRMLink;
                                }))
                            .then(() => {
                                store.setState( newState );
                            });
                    } else {
                        newState.UI.isSelectedCenter = false;
                    }

                    store.setState( newState );
                },
            }, {
                type: 'list',
                field: Config.Qlik.app1.ids.fields['PROF ID'],
                name: 'PROF',
                callback: ( reply, handle ) => {
                    const newState = store.state;
                    registerListCallback( reply, handle, store );

                    // isStaffSelected handles if role is chosen on staff info and only one staff is availabale for that role - don't show staff card in this instance
                    if ( reply.qListObject.qDimensionInfo.qStateCounts.qSelected === 1 || ( reply.qListObject.qDimensionInfo.qStateCounts.qOption === 1 && isStaffSelected )) {
                        newState.UI.isSelectedStaff = true;

                        if ( reply.qListObject.qDimensionInfo.qStateCounts.qSelected === 0 || reply.qListObject.qDimensionInfo.qStateCounts.qOption === 1 ) {
                            // Select the Staff Field
                            Mosaic.Qlik.app[appId].field( reply.qListObject.qDimensionInfo.qFallbackTitle ).selectPossible();
                        }

                        if ( !newState.UI.showStaffCard ) {
                            openStaffCard( store );
                            newState.UI.showStaffCard = true;
                        }

                         Mosaic.Qlik.app[appId].variable.getContent( 'vProfImageFile' )
                        .then(( replyVar4 ) => {
                            const vProfImgURL = Config.App.profImagesServer + replyVar4.qContent.qString;
                            newState.UI.imgProfURL = vProfImgURL;
                        })
                        .then(() => {
                            Mosaic.Qlik.app[appId].forward();
                        })
                        .then(() => {
                            store.setState( newState );
                        });
                    } else {
                        newState.UI.imgProfURL = `${Config.App.profImagesServer}default_profimage.png`;
                        newState.UI.isSelectedStaff = false;
                    }
                    store.setState( newState );
                },
            }, {
            //     type: 'list',
            //     field: Config.Qlik.app1.ids.fields['PROF IMAGE URL'],
            //     name: 'PROF IMG URL',
            //     callback: ( reply, handle ) => {
            //         registerListCallback( reply, handle, store );

            //         if ( reply.qListObject.qDimensionInfo.qStateCounts.qOption === 1 && reply.qListObject.qDataPages[0] && reply.qListObject.qDataPages[0].qMatrix[0] && reply.qListObject.qDataPages[0].qMatrix[0][0].qState === 'O' ) {
            //             profImgUrl = Config.App.profImagesServer + reply.qListObject.qDataPages[0].qMatrix[0][0].qText.split( 'ProfImages\\' )[1];
            //         } else {
            //             profImgUrl = `${Config.App.profImagesServer}UnknownDoc.jpg`;
            //         }
            //     },
            // }, {
                type: 'list',
                field: Config.Qlik.app1.ids.fields['Website URL'],
                name: 'WEBSITE URL',
                callback: ( reply, handle ) => {
                    registerListCallback( reply, handle, store );

                    if ( reply.qListObject.qDataPages[0] && reply.qListObject.qDataPages[0].qMatrix[0] && reply.qListObject.qDataPages[0].qMatrix[0][0].qState === 'O' ) {
                        return;
                    } else {
                        return;
                    }
                },
            }, {
                type: 'generic',
                template: { value: { qStringExpression: '=only(BMTC.HAS_PURCHASED)' } },
                name: 'SD Purchased',
                callback: ( object ) => {
                    const newState = store.state;
                    let hasOrgPurchased = false;
                    if ( object.value !== 'NO' ) {
                        hasOrgPurchased = true;
                    }
                    if ( hasOrgPurchased !== newState.UI.hasOrgPurchased ) {
                        newState.UI.hasOrgPurchased = hasOrgPurchased;
                        store.setState( newState );
                    }
                },
        },
        ];

        listeners.forEach(( val ) => {
            let object;
            const handle = val.name || `Object${sessionObjects.length}`;
            if ( val.type && val.type === 'list' ) {
                object = app.createList(
                    {
                        qDef: {
                            qFieldDefs: [val.field],
                            qSortCriterias: [{ qSortByState: 1 }],
                        },
                        qInitialDataFetch: [{
                            qTop: 0,
                            qLeft: 0,
                            qHeight: 20,
                            qWidth: 1,
                        }],
                        qShowAlternatives: false,
                    }, ( reply ) => { val.callback( reply, handle ); },
                );

                selectionsManager.objects[handle] = { latestCallback: Date.now() };
            } else if ( val.type && val.type === 'generic' ) {
                object = app.createGenericObject( val.template, ( reply ) => { val.callback( reply, handle ); });
            } else if ( val.type && val.type === 'table' ) {
                // tableObjects.push( app.createTable( val.dimensions, val.measures ) );
                // const listener = () => {
                //     console.log('ondataboind');
                //     val.callback( tableObjects[tableObjects.length - 1] );
                // };
                // tableObjects[tableObjects.length - 1].OnData.bind( listener );
            } else {
                return;
            }

            sessionObjects.push({
                name: handle,
                object,
            });
            selectionsManager.appId = appId;
        });
    }
};

export const setIsSelectedCenter = ( store, isSelectedCenter ) => {
    store.setState({ isSelectedCenter });
};

export const clearAppSelections = ( store, appId ) => {
    const Qlik = { ...store.state.Qlik };
    Qlik.app[appId].selectionsLastModified = Date.now();
    store.setState({ Qlik });
    Mosaic.Qlik.app[appId].clearAll( true );
};

export const clearFieldSelections = ( store, appId, fieldName ) => {
    Mosaic.Qlik.app[appId].field( fieldName ).clear();
};

export const clearOtherFieldSelections = ( store, appId, fieldName ) => {
    Mosaic.Qlik.app[appId].field( fieldName ).clearOther( true );
};

export const bookmarkApply = ( store, appId, bookmarkId ) => {
    Mosaic.Qlik.app[appId].bookmark.apply( bookmarkId );
};

export const bookmarkCreate = ( store, appId, title, description ) => {
    Mosaic.Qlik.app[appId].bookmark.create( title, description );
};

export const bookmarkDelete = ( store, appId, bookmarkId ) => {
    Mosaic.Qlik.app[appId].bookmark.remove( bookmarkId );
};
